import { NavLink } from 'react-router-dom';

import styles from './Menu.module.css';

const Menu = ({ items, onClickItem }) => {
  const numOfColumns = Math.ceil(items?.length / 5);
  const columns = [];

  for (let i = 0; i < numOfColumns; i++) {
    columns.push(items?.slice(i * 5, i * 5 + 5));
  }

  return (
    <div className={styles.container}>
      {columns.map((col, i) => (
        <div className={styles.column} key={`${i}column`}>
          {col.map((item, j) => (
            <div className={styles.item} key={`${j}${item?.text}`}>
              <NavLink
                className={({ isActive }) => {
                  return styles.itemNavLink;
                }}
                to={item?.link}
                onClick={onClickItem}
              >
                {item?.text}
              </NavLink>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;
