import { Outlet } from 'react-router-dom';

import Navbar from './Navbar/Navbar';
import CustomSuspense from '../CustomSuspense/CustomSuspense';
import styles from './Layout.module.css';

const Layout = ({ navBarItems }) => {
  return (
    <div className={styles.layout}>
      <Navbar navBarItems={navBarItems} />
      <CustomSuspense>
        <Outlet />
      </CustomSuspense>
    </div>
  );
};

export default Layout;
