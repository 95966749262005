import { Outlet } from 'react-router-dom';
import ViewItems from './ViewItems/ViewItems';

import styles from './ViewLayout.module.css';

const ViewLayout = ({ viewItems }) => {
  return (
    <div className={styles.container}>
      <ViewItems viewItems={viewItems} />
      <Outlet />
    </div>
  );
};

export default ViewLayout;
