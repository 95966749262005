import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import ViewLayout from '../../components/ViewLayout/ViewLayout';

import calculatorRoutesData from './calculator.json';

const AgeCalculator = lazy(() => import('../calculator/DateTime/AgeCalculator/AgeCalculator'));
const DateCalculator = lazy(() => import('../calculator/DateTime/DateCalculator/DateCalculator'));
const DateTimeCalculator = lazy(() => import('../calculator/DateTime/DateTimeCalculator/DateTimeCalculator'));
const TimeCalculator = lazy(() => import('../calculator/DateTime/TimeCalculator/TimeCalculator'));
const DateOperations = lazy(() => import('../calculator/DateTime/DateOperations/DateOperations'));
const DateTimeOperations = lazy(() => import('../calculator/DateTime/DateTimeOperations/DateTimeOperations'));
const TimeOperations = lazy(() => import('../calculator/DateTime/TimeOperations/TimeOperations'));

const Router = () => {
  return (
    <Routes>
      <Route path="calculator" element={<Layout navBarItems={calculatorRoutesData} />}>
        <Route index element={<Navigate to="/calculator/datetime/age-calculator" />} />
        <Route path="datetime" element={<ViewLayout viewItems={calculatorRoutesData[0].viewItems} />}>
          <Route index element={<Navigate to="/calculator/datetime/age-calculator" />} />
          <Route path="age-calculator" element={<AgeCalculator />} />
          <Route path="datetime-calculator" element={<DateTimeCalculator />} />
          <Route path="time-calculator" element={<TimeCalculator />} />
          <Route path="date-calculator" element={<DateCalculator />} />
          <Route path="date-add-subtract-calculator" element={<DateOperations />} />
          <Route path="time-add-subtract-calculator" element={<TimeOperations />} />
          <Route path="datetime-add-subtract-calculator" element={<DateTimeOperations />} />
          <Route path="*" element={<Navigate to="/calculator/datetime/age-calculator" />} />
        </Route>
        {/* <Route path="finance" element={<ViewLayout viewItems={calculatorRoutesData[1].viewItems} />}>
          <Route index element={<Navigate to="/calculator/finance/simple-interest" />} />
          <Route path="simple-interest" element={<p>Simple Interest</p>} />
          <Route path="compound-interest" element={<p>Compound Interest</p>} />
          <Route path="emi-equated-monthly-instalments" element={<p>EMI</p>} />
          <Route path="sip-systematic-investment-plan" element={<p>SIP</p>} />
          <Route path="stepup-sip-systematic-investment-plan" element={<p>Stepup SIP</p>} />
          <Route path="swp-systematic-withdrawal-plan" element={<p>SWP</p>} />
          <Route path="*" element={<Navigate to="/calculator/finance/simple-interest" />} />
        </Route> */}
        <Route path="*" element={<Navigate to="/calculator" />} />
      </Route>
      <Route path="*" element={<Navigate to="/calculator" />} />
    </Routes>
  );
};

export default Router;
