import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import MuiMenu from '@mui/material/Menu';

import Menu from './Menu/Menu';

import viewItemStyles from '../ViewItem/ViewItem.module.css';
import styles from './DropdownViewItems.module.css';

const DropdownViewItems = ({ viewItems }) => {
  const [viewMore, setViewMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setViewMore(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setViewMore(false);
  };

  return (
    <>
      <div
        id="basic-button"
        className={`${styles.container} ${viewItemStyles.viewItem} ${viewItemStyles.inactiveViewItem}`}
        onClick={handleClick}
      >
        <span className={styles.viewMore}>More</span>
        <span className={styles.viewMoreDropdown}>{viewMore ? <BsChevronUp /> : <BsChevronDown />}</span>
      </div>
      <MuiMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={viewMore}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Menu items={viewItems} onClickItem={handleClose} />
      </MuiMenu>
    </>
  );
};

export default DropdownViewItems;
