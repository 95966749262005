import { useState } from 'react';
import { BsTextRight } from 'react-icons/bs';

import NavbarItem from './NavbarItem/NavbarItem';
import Logo from '../../../assets/icons/Logo_v1.png';

import styles from './Navbar.module.css';

const Navbar = ({ navBarItems }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const showDrawerHandler = () => setShowDrawer((showDrawer) => !showDrawer);

  return (
    <>
      <nav className={styles.nav}>
        <img className={styles.logo} src={Logo} alt="Get it done logo" />
        {navBarItems?.map((navBarItem) => (
          <NavbarItem navBarItem={navBarItem} key={navBarItem?.text} />
        ))}
      </nav>
      <nav className={styles.mobileNav}>
        <img className={styles.mobileLogo} src={Logo} alt="Get it done logo" />
        <div className={styles.mobileBurgerIcon} onClick={showDrawerHandler}>
          <BsTextRight />
        </div>
      </nav>
      <>
        <div className={showDrawer ? styles.drawerBackground : null} onClick={showDrawerHandler}></div>
        <div className={`${styles.drawer} ${showDrawer ? styles.activeDrawer : styles.inactiveDrawer}`}>
          <div className={styles.drawerNavLink}>
            {navBarItems?.map((navBarItem) => (
              <NavbarItem navBarItem={navBarItem} key={navBarItem?.text} showDrawerHandler={showDrawerHandler} />
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Navbar;
