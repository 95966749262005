import { NavLink } from 'react-router-dom';

import styles from './ViewItem.module.css';

const ViewItem = ({ viewItem }) => {
  return (
    <NavLink
      to={viewItem?.link}
      className={({ isActive }) => {
        return isActive
          ? `${styles.viewItem} ${isActive ? styles.activeViewItem : null}`
          : `${styles.viewItem} ${styles.inactiveViewItem}`;
      }}
    >
      {viewItem?.text}
    </NavLink>
  );
};

export default ViewItem;
