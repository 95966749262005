import { useEffect } from 'react';
import Router from './containers/Router/Router';

import { setupFirebase } from './firebase/firebase';

function App() {
  useEffect(() => {
    setupFirebase();
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
