import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ViewItem from './ViewItem/ViewItem';
import DropdownViewItems from './DropdownViewItems/DropdownViewItems';

import styles from './ViewItems.module.css';

const ViewItems = ({ viewItems }) => {
  const [viewItemsState, setViewItemsState] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [restItems, setRestItems] = useState([]);
  const [numOfDisplayedItems, setNumOfDisplayedItems] = useState(window.innerWidth <= 600 ? 1 : 3);
  const [width, setWidth] = useState(window.innerWidth);
  let location = useLocation();

  useEffect(() => {
    const currNumOfDisplayedItems = width <= 600 ? 1 : 3;
    if (currNumOfDisplayedItems !== numOfDisplayedItems) {
      setNumOfDisplayedItems(currNumOfDisplayedItems);
      setDisplayedItems(viewItemsState?.slice(0, currNumOfDisplayedItems));
      setRestItems(viewItemsState?.slice(currNumOfDisplayedItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setViewItemsState(viewItems?.slice());
    setDisplayedItems(viewItems?.slice(0, numOfDisplayedItems));
    setRestItems(viewItems?.slice(numOfDisplayedItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewItems]);

  useEffect(() => {
    const viewItemIndex = viewItemsState?.findIndex((viewItem) => viewItem?.link === location?.pathname);
    if (viewItemIndex < numOfDisplayedItems) {
      return;
    }

    setViewItemsState((viewItemsState) => {
      const viewItem = viewItemsState?.splice(viewItemIndex, 1);
      viewItemsState.unshift(viewItem[0]);
      setDisplayedItems(viewItemsState?.slice(0, numOfDisplayedItems));
      setRestItems(viewItemsState.slice(numOfDisplayedItems));
      return viewItemsState;
    });
  }, [location, viewItemsState, numOfDisplayedItems]);

  return (
    <div className={styles.viewItems}>
      {displayedItems?.map((viewItem, i) => (
        <ViewItem viewItem={viewItem} key={i + viewItem?.text} />
      ))}

      {restItems?.length > 0 && <DropdownViewItems viewItems={restItems} />}
    </div>
  );
};

export default ViewItems;
