import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavbarItem.module.css';

const NavbarItem = ({ navBarItem, showDrawerHandler }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.navLink}>
      <NavLink
        to={navBarItem?.link}
        className={({ isActive }) => {
          setIsActive(isActive);
          return isActive ? styles.activeLink : styles.inactiveLink;
        }}
        onClick={showDrawerHandler}
      >
        <div>
          {navBarItem?.text}
          <div className={`${styles.divider} ${isActive ? styles.activeDivider : null}`}></div>
        </div>
      </NavLink>
    </div>
  );
};

export default NavbarItem;
